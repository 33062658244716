import styled from "styled-components";
import { Header2 } from "../styles/content";

const StyledHowItWorks = styled.div`
  width: 100%;
  padding: 4rem;
  max-width: 135rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    padding: 0;
  }
`;

const Items = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.4rem;

  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Item = styled.div`
  background: var(--bg-1);
  padding: 3.2rem;
  border: var(--border);
  border-radius: 2.4rem;

  border-radius: 24px;
  border: 1px solid #705d63;
  background: linear-gradient(180deg, #1b1417 0%, rgba(23, 19, 20, 0.52) 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: 900px) {
    padding: 1.4rem 2.4rem;
  }
`;

const Number = styled.div`
  color: #bbb3b7;
  font-size: 10.8rem;
  font-weight: 200;
  margin-bottom: 1.6rem;
  line-height: 1;
`;

const Header = styled.h3`
  font-size: 4.8rem;
  font-weight: 300;
  color: #bbb3b7;
  line-height: 1;
  margin-bottom: 1.6rem;
`;

const Body = styled.p`
  font-size: 2rem;
  font-weight: 300;
  color: var(--sub);
`;

const HowItWorks = () => {
  return (
    <StyledHowItWorks>
      <Header2>How it works</Header2>
      <Items>
        <Item>
          <Number>01</Number>
          <Header>Mint</Header>
          <Body>
            Choose your target asset, leverage and direction, and mint leveraged
            tokens using USDC or sUSD
          </Body>
        </Item>
        <Item>
          <Number>02</Number>
          <Header>Hold</Header>
          <Body>
            Lean back and relax while automatic rebalancing manages your
            position
          </Body>
        </Item>
        <Item>
          <Number>03</Number>
          <Header>Redeem</Header>
          <Body>Redeem your position at any time for USDC, sUSD</Body>
        </Item>
      </Items>
    </StyledHowItWorks>
  );
};

export default HowItWorks;
