import styled from "styled-components";
import Popup from "./Popup";
import Button from "./Button";
import { useEthers } from "@usedapp/core";
import Step from "./Step";
import { providers } from "ethers";
import useLoginSignature from "../app/hooks/use-login-signature";
import LoadingSpinner from "./LoadingSpinner";
import { useLocation, useNavigate } from "react-router-dom";
import {
  HOME_PATH,
  LOCK_PATH,
  MINT_PATH,
  PORTFOLIO_PATH,
  REWARDS_PATH,
  STAKE_PATH,
  VESTING_PATH,
} from "../app/constants/paths";
import { useDispatch } from "react-redux";
import { setError } from "../state/uiSlice";

const PROTOCOL_PATHS = [
  STAKE_PATH,
  MINT_PATH,
  PORTFOLIO_PATH,
  LOCK_PATH,
  REWARDS_PATH,
  VESTING_PATH,
];

const StyledLogin = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 52rem;
  padding: 0 1.6rem;

  @media (max-width: 900px) {
    width: 100%;
    padding: 0;
  }
`;

const MESSAGE =
  "I agree to the TLX Terms of Service (https://tlx.fi/terms-of-service) and TLX Privacy Policy (https://tlx.fi/privacy-policiy). I acknowledge that TLX integrates with third-party applications, which may come with risks";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { library, active, account, activateBrowserWallet, isLoading } =
    useEthers();
  const { signature, setSignature } = useLoginSignature();

  const connected = active && !!account && !!library;

  const authorized = !!signature;

  const isProtocolPage = PROTOCOL_PATHS.includes(
    location.pathname.substring(1)
  );

  return (
    <Popup
      open={!authorized && isProtocolPage}
      close={() => navigate(HOME_PATH)}
      header="Login"
    >
      <StyledLogin style={{ opacity: isLoading ? 0 : 1 }}>
        <Step completed={connected} label="Connect browser wallet">
          {!connected && (
            <Button primary action={activateBrowserWallet}>
              Connect
            </Button>
          )}
        </Step>
        <Step completed={authorized} label="Accept Terms" disabled={!connected}>
          {!authorized && (
            <Button
              primary
              disabled={!connected}
              action={async () => {
                if (!library || !account) return;
                if (!(library instanceof providers.JsonRpcProvider)) return;
                try {
                  const sig = await library
                    .getSigner(account)
                    .signMessage(MESSAGE);
                  setSignature(sig);
                } catch (e: any) {
                  dispatch(setError({ message: e.message, source: "Login" }));
                }
              }}
            >
              Sign
            </Button>
          )}
        </Step>
      </StyledLogin>
      <LoadingSpinner show={isLoading} />
    </Popup>
  );
};

export default Login;
