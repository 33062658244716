import { useEffect, useState } from "react";

const useCountry = () => {
  const [country, setCountry] = useState<string | null>(null);

  const getCountry = async () => {
    const response = await fetch("https://api.country.is/");
    const data = await response.json();
    setCountry(data.country);
  };

  useEffect(() => {
    getCountry();
  }, []);

  return country;
};

export default useCountry;
