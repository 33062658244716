import { useState } from "react";
import styled from "styled-components";
import Button from "./Button";

interface DynamicProps {
  $success?: boolean;
  $warning?: boolean;
  $primary?: boolean;
}

const StyledBanner = styled.div<DynamicProps>`
  width: 96rem;
  height: 7.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  border-radius: 3.6rem;
  padding: 0 4rem;

  background: ${(props) => {
    if (props.$primary) return "var(--primary-bg)";
    if (props.$success) return "var(--success-bg)";
    if (props.$warning) return "var(--warning-bg)";
    return "var(--bg-2)";
  }};

  @media (max-width: 900px) {
    flex-direction: column;
    width: 100%;
    padding: 0.8rem;
    height: auto;
    border-radius: 2rem;
    margin-bottom: 2rem;
  }
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  button {
    margin-left: 2.4rem;
  }

  @media (max-width: 900px) {
    width: 100%;
    margin: auto;

    button {
      margin-top: 1.6rem;
      margin-left: 0;
    }
  }
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    margin: auto;
  }
`;

const Icon = styled.img`
  height: 3.2rem;
  margin-right: 1.6rem;
`;

const Label = styled.div<DynamicProps>`
  font-size: 2.4rem;
  font-weight: 400;
  color: ${(props) => {
    if (props.$success) return "var(--success)";
    if (props.$warning) return "var(--warning)";
    return "var(--primary)";
  }};

  @media (max-width: 900px) {
    text-align: center;
  }
`;

const SubLabel = styled.div`
  margin-left: 1.6rem;
  font-size: 1.6rem;
  font-weight: 500;
`;

const SubLabelLink = styled.a`
  margin-left: 1.6rem;
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: underline;
  color: var(--main);
`;

const MobileOnly = styled.div`
  display: none;
  width: 100%;

  @media (max-width: 900px) {
    display: flex;
  }
`;

const DesktopOnly = styled.div`
  display: flex;

  @media (max-width: 900px) {
    display: none;
  }
`;

interface Props {
  icon?: string;
  label: string;
  subLabel?: string;
  subLabelLink?: string;
  buttonText?: string;
  buttonAction?: () => void;
  success?: boolean;
  warning?: boolean;
  primary?: boolean;
}

const Banner = ({
  icon,
  label,
  subLabel,
  subLabelLink,
  buttonText,
  buttonAction,
  success,
  warning,
  primary,
}: Props) => {
  const [show, setShow] = useState(true);

  if (!show) return null;

  const color = success
    ? "var(--success)"
    : warning
    ? "var(--warning)"
    : "var(--primary)";

  return (
    <StyledBanner $success={success} $warning={warning} $primary={primary}>
      <Section>
        <TopSection>
          {icon && <Icon src={icon} alt="icon" />}
          <Label $success={success} $warning={warning} $primary={primary}>
            {label}
          </Label>
          {subLabel && !subLabelLink && <SubLabel>{subLabel}</SubLabel>}
          {subLabel && subLabelLink && (
            <SubLabelLink href={subLabelLink} target="_blank">
              {subLabel}
            </SubLabelLink>
          )}
        </TopSection>
      </Section>
      <Section>
        {buttonText && buttonAction && (
          <>
            <DesktopOnly>
              <Button primary action={buttonAction} color={color}>
                {buttonText}
              </Button>
            </DesktopOnly>
            <MobileOnly>
              <Button wide primary action={buttonAction} color={color}>
                {buttonText}
              </Button>
            </MobileOnly>
          </>
        )}
        {!primary && (
          <Button action={() => setShow(false)} color={color}>
            Dismiss
          </Button>
        )}
      </Section>
    </StyledBanner>
  );
};

export default Banner;
