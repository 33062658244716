export const MINTING_DISABLED = false;
export const PNL_LIVE = false;
export const LANDING_PAGE_SECTIONS = 6;
export const PRICE_IMPACT_WARNING_THRESHOLD = 0.005; // 0.5%
export const PRICE_IMPACT_GAIN_THRESHOLD = 0.001; // 0.1%
export const DEBOUNCE_DELAY = 1000; // 1s
export const BLOCKED_COUNTRIES = [
  "BY",
  "CN",
  "CU",
  "CD",
  "IR",
  "IQ",
  "LR",
  "KP",
  "SD",
  "SS",
  "ZW",
  "US",
  "UM",
  "CI",
  "LB",
  "LY",
  "RU",
  "GB",
  "SO",
  "VE",
  "YE",
  "UA",
];
