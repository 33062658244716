import styled from "styled-components";

import user from "../assets/nav/user.svg";
import userPink from "../assets/nav/user-pink.svg";
import newIcon from "../assets/nav/new.svg";
import newPink from "../assets/nav/new-pink.svg";
import rewards from "../assets/nav/rewards.svg";
import rewardsPink from "../assets/nav/rewards-pink.svg";
import tlx from "../assets/nav/tlx.svg";
import tlxPink from "../assets/nav/tlx-pink.svg";
import { Link, useLocation } from "react-router-dom";
import {
  MINT_PATH,
  PORTFOLIO_PATH,
  REWARDS_PATH,
  STAKE_PATH,
} from "../app/constants/paths";

interface NavItemType {
  path: string;
  icon: string;
  activeIcon: string;
}

const NAV_ITEMS: NavItemType[] = [
  {
    path: `/${MINT_PATH}`,
    icon: newIcon,
    activeIcon: newPink,
  },
  {
    path: `/${PORTFOLIO_PATH}`,
    icon: user,
    activeIcon: userPink,
  },
  {
    path: `/${STAKE_PATH}`,
    icon: tlx,
    activeIcon: tlxPink,
  },
  {
    path: `/${REWARDS_PATH}`,
    icon: rewards,
    activeIcon: rewardsPink,
  },
];

const StyledMobileNav = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5.6rem;
  background: var(--bg);
  z-index: 2;
  padding: 1.6rem;
  padding-top: 0;

  @media (max-width: 1100px) {
    display: flex;
  }
`;

const Shadow = styled.div`
  position: absolute;
  top: -1.6rem;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--bg);
  filter: blur(0.8rem);
`;

const NavBar = styled.div`
  position: relative;
  background: var(--bg-1);
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  padding: 0.4rem 0.8rem;
  display: flex;
  justify-content: space-between;
  z-index: 2;
`;

interface ItemProps {
  $active: boolean;
}

const Item = styled(Link)<ItemProps>`
  position: relative;
  height: 100%;
  aspect-ratio: 1;
  background: ${(props: ItemProps) =>
    props.$active ? "var(--disabled)" : "none"};
  border-radius: 0.8rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IconProps {
  $disabled?: boolean;
}

const Icon = styled.img<IconProps>`
  height: 2rem;
  opacity: ${(props: IconProps) => (props.$disabled ? 0.5 : 1)};
`;

const MobileNav = () => {
  const location = useLocation();

  return (
    <StyledMobileNav>
      <Shadow />
      <NavBar>
        {NAV_ITEMS.map((item) => {
          const active = location.pathname === item.path;
          return (
            <Item key={item.path} to={item.path} $active={active}>
              <Icon src={active ? item.activeIcon : item.icon} />
            </Item>
          );
        })}
      </NavBar>
    </StyledMobileNav>
  );
};

export default MobileNav;
