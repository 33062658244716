export const SUPPORTED_LEVERAGE: Record<string, number[]> = {
  // Blue Chip
  ETH: [1, 2, 3, 4, 5, 7],
  SOL: [1, 2, 3, 4, 5],
  BTC: [1, 2, 3, 4, 5, 7],

  // Long Tail
  LINK: [2, 5],
  OP: [2, 5],
  PEPE: [2, 5],
  DOGE: [2, 5],
  SUI: [2, 5],
  SEI: [2, 5],

  // Low Volatility
  ETHBTC: [2, 5, 10],
};
