const getCountdownText = (time: Date) => {
  const now = new Date();
  const timeRemaining = time.getTime() - now.getTime();

  const MINUTE = 1000 * 60;
  const HOUR = MINUTE * 60;
  const DAY = HOUR * 24;
  const WEEK = DAY * 7;
  const weeks = Math.floor(timeRemaining / WEEK);
  const days = Math.floor((timeRemaining - weeks * WEEK) / DAY);
  const hours = Math.floor((timeRemaining - weeks * WEEK - days * DAY) / HOUR);
  const minutes = Math.floor(
    (timeRemaining - weeks * WEEK - days * DAY - hours * HOUR) / MINUTE
  );

  let unlockText = "";
  if (weeks > 0) unlockText += ` ${weeks} week${weeks > 1 ? "s" : ""},`;
  if (days > 0) unlockText += ` ${days} day${days > 1 ? "s" : ""},`;
  if (hours > 0) unlockText += ` ${hours} hour${hours > 1 ? "s" : ""}`;
  if (minutes > 0 && days === 0)
    unlockText += ` and ${minutes} minute${minutes > 1 ? "s" : ""}`;
  unlockText += ".";
  unlockText = unlockText.replace(/,([^,]*)$/, " and$1");
  unlockText = unlockText.replace(" and.", ".");
  unlockText = unlockText.replace(".", "");
  unlockText = unlockText.trim();

  return unlockText;
};

export default getCountdownText;
