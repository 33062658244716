// Protocol
const DEPLOYMENTS = {
  AddressProvider: "0xbaa87ecc5dd76526b51ab7fd2d0c814eb967e2e2",
  ChainlinkAutomation: "0x8C79137806a35cfE04dbb4D8D3F4F0da35eaFB4d",
  GenesisLocker: "0xc068c3261522c97ff719dc97c98c63a1356fef0f",
  LeveragedTokenFactory: "0x5Dd85f51e9fD6aDE8ecc216C07919ecD443eB14d",
  LeveragedTokenHelper: "0x8E61715363435d40B0Dbb35524a03517b9d8d01D",
  LiquidationPointsClaimer: "0x4a1eE35F7A41B72c65AC429EC92c1221Be78404c",
  ParameterProvider: "0xd85c61e96fd1805f0de12165ca126243617a57b0",
  Referrals: "0x756869855e6857E6618BD71646B23F69A054a8c7",
  Staker: "0xc30877315f3b621a8f7bcda27819ec29429f3817",
  SynthetixHandler: "0xa86B0c4Cf984Cf2cf47CCDF96462fe40cD92D46E",
  TLX: "0xd9cc3d70e730503e7f28c1b407389198c4b75fa2",
  Vesting: "0xebd707214ea4bae19e826f14ec2a82d1b44a75f3",
  ZapSwap: "0x28b82692c8010C0f73b32822Ed1e480c9032b6CB",
  SynthetixHandlerStorage: "0xDbf28C96ACEBd4d1b8c1DC4DA4cFc25302704b7e",
};

export const ADDRESS_PROVIDER_ADDRESS = DEPLOYMENTS.AddressProvider;
export const CHAINLINK_AUTOMATION_ADDRESS = DEPLOYMENTS.ChainlinkAutomation;
export const FACTORY_ADDRESS = DEPLOYMENTS.LeveragedTokenFactory;
export const LEVERAGED_TOKEN_HELPER_ADDRESS = DEPLOYMENTS.LeveragedTokenHelper;
export const STAKER_ADDRESS = DEPLOYMENTS.Staker;
export const LOCKER_ADDRESS = DEPLOYMENTS.GenesisLocker;
export const PARAMETER_PROVIDER_ADDRESS = DEPLOYMENTS.ParameterProvider;
export const REFERRALS_ADDRESS = DEPLOYMENTS.Referrals;
export const SYNTHETIX_HANDLER_ADDRESS = DEPLOYMENTS.SynthetixHandler;
export const TLX_ADDRESS = DEPLOYMENTS.TLX;
export const VESTING_ADDRESS = DEPLOYMENTS.Vesting;
export const ZAP_SWAP_ADDRESS = DEPLOYMENTS.ZapSwap;
export const SYNTHETIX_STORAGE_ADDRESS = DEPLOYMENTS.SynthetixHandlerStorage;

// Contracts
export const VELODROME_POOL = "0x6eA20Dbc05f58ED607c374e545BFb402d5796770";
export const VELODROME_GAUGE = "0x3132cC562C39Fb9ABaeA11411987031C5144C85D";
export const MULTICALL_ADDRESS = "0xcA11bde05977b3631167028862bE2a173976CA11";

// Tokens
export const SUSD = "0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9";
export const VELO = "0x3c8B650257cFb5f272f799F5e2b4e65093a11a05";
export const USDC = "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85";
export const USDCE = "0x7F5c764cBc14f9669B88837ca1490cCa17c31607";
export const DAI = "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1";
export const USDT = "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58";
export const WETH = "0x4200000000000000000000000000000000000006";
